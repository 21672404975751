require=(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({11:[function(require,module,exports){
'use strict';

var _helpers = require('./_helpers');

var _sectionsnav = require('./_sectionsnav');

var subnav = _interopRequireWildcard(_sectionsnav);

var _header = require('./_header');

var header = _interopRequireWildcard(_header);

var _jquery = require('jquery');

var _jquery2 = _interopRequireDefault(_jquery);

var _TweenMax = require('./vendors/gs/TweenMax.min');

var _TweenMax2 = _interopRequireDefault(_TweenMax);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

// subnav goodies
subnav.initNav();
header.init();
// subnav.setSubnavActive('drawingoffice');

// TweenMax.from('body', 1, {autoAlpha: 0, delay: 1});

doAnimations('.icon-indoor');
doAnimations('.icon-outdoor');
doAnimations('.icon-drawingoffice');
doAnimations('.icon-dyesublimation');
doAnimations('.icon-hp');
doAnimations('.icon-canon');
// tl.to('.ani1', 1, {rotation: Math.random() * 360, transformOrigin: "50% 50%", delay: 2});

function doAnimations(parent) {
    var tl = new TimelineMax();
    if (!(0, _helpers.isMobile)()) {
        tl.to(parent + ' .ani1', Math.random() * 3 + 1, { rotation: Math.random() * 360, transformOrigin: "50% 50%", delay: Math.random() * 3 }).to(parent + ' .ani2', Math.random() * 3 + 1, { rotation: Math.random() * 360, transformOrigin: "50% 50%", delay: Math.random() * 3 }).to(parent + ' .ani1', Math.random() * 3 + 1, { rotation: 0, transformOrigin: "50% 50%", delay: Math.random() * 3 }).to(parent + ' .ani2', Math.random() * 3 + 1, { rotation: 0, transformOrigin: "50% 50%", delay: Math.random() * 3 }).call(doAnimations, [parent]);
    }
}

},{"./_header":4,"./_helpers":5,"./_sectionsnav":6,"./vendors/gs/TweenMax.min":16,"jquery":1}]},{},[11]);
